import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import Cards from "../components/Cards"
import FeaturesHero from "../components/FeaturesHero"
import Demo from "../components/Demo"
import SiteMetadata from "../components/SiteMetadata"
import FaqWrapper from "../components/FaqWrapper"
import ContactBanner from "../components/ContactBanner"

const CustomFeaturesPage = ({ data }) => (
  <Layout>
    <SiteMetadata
      title="Zurly Features for Fundraisers"
      description="Digital fundraiser and loyalty card features from Zurly"
    />
    <FeaturesHero
      h1={data.hero.h1Text}
      category={data.hero.handwrittenText}
      paragraph={data.hero.paragraphText.paragraphText}
      image={data.hero.svgImage}
      alt={data.hero.svgImage.description}
      button={data.hero.buttonText}
      href="/contact"
    />
    <Demo
      cards={data.demos.nodes}
      titleAlign="text-center"
      title="What a custom app can look like"
      bgColor=""
      bottomPadding="pb-20"
    />
    <div className="bg-customlightgray">
      {data.resources.edges && data.resources.edges.length > 0 ? (
        <Cards items={data.resources.edges} />
      ) : (
        <></>
      )}
    </div>
    <FaqWrapper faqs={data.faqs} />
    <ContactBanner />
  </Layout>
)

export default CustomFeaturesPage

export const query = graphql`
  query {
    hero: contentfulHeros(handwrittenText: { eq: "CUSTOM APP" }) {
      handwrittenText
      h1Text
      buttonText
      paragraphText {
        paragraphText
      }
      svgImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
    benefitsCards: allContentfulBenefitCard(
      filter: { section: { eq: "features fundraiser" } }
      limit: 2
    ) {
      nodes {
        featureBenefits
        featureTitle
        icon {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
          title
        }
      }
    }
    demos: allContentfulFeaturedDemo(filter: { tag: { eq: "fundraising" } }) {
      nodes {
        description
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          description
        }
        tag
        link
      }
    }
    faqs: allContentfulFaqsCustom(sort: { fields: order }) {
      nodes {
        id
        order
        answer {
          answer
        }
        question {
          question
        }
      }
    }
    resources: allContentfulBlogPost(
      filter: { tags: { eq: "custom" } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          title
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
  }
`
